import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element-plus
import '@assets/styles/element-plus/index.scss'
// style
import '@assets/styles/index.scss'
// permission - 路由授权许可
import '@/permission'
// empty-box - 空组件
import EmptyBox from '@/components/EmptyBox/index'
import RouterIcon from '@/components/RouterIcon/index'
import { updateUserInfo } from '@/utils/auth'
import { initLanguage } from '@/language'
import directive from '@/directive'

(window as any)._AMapSecurityConfig = {
  securityJsCode: '326820fb70b26457fe44b07e8036cf7b'
}

const app = createApp(App)
app.use(EmptyBox)
app.use(RouterIcon)
app.use(store).use(router).mount('#app')

directive.install(app)

// 更新用户信息
// updateUserInfo()
// 初始化语言
initLanguage()

// 异步操作
// Promise.all(<any>updateUserInfo()).then(() => {
//   // --
// }).catch(err => {
//   result().then(err)
// }).finally(() => {
//   // --
// })
